import { type TokenRef, useJwtTokens } from "~/hooks/useJwtTokens";
import { useNuxtApp, useRouter } from "#app";
import { defineStore } from "pinia";

export const USER_STATUSES = {
  ACTIVE: "active",
  PRIVACY: "privacy_policy_not_accepted"
};

export const useAuthStore = defineStore("auth", {
  state: () => ({
    id: null, // user id
    email_address: null, // user email address
    status: {},
    errors: {},
    non_field_errors: [],
    message: null,
    code: "login",
    key: null
  }),
  getters: {
    get_errors(state) {
      return new Proxy(state.errors, {
        get: (target, prop) => {
          if (prop in target) {
            if (Array.isArray(target[prop])) {
              return target[prop].join(", ");
            }
            return target[prop];
          }
          return "";
        }
      });
    },
    get_non_field_errors(state) {
      return state.non_field_errors;
    }
  },
  actions: {
    async initialise() {
    },
    async register(register_data) {
      // make an API call to register the user
      const { $api } = useNuxtApp();
      const { data, error } = await $api.auth.register(register_data);

      if (error.value) {
        this.validation_errors(error.value.data);
        return false;
      } else {
        this.parse_response(data.value);
        this.status = { registrationInitiated: true };
        this.reset_errors();
        return true;
      }

    },
    async getUser() {
    },
    async get_user() {
    },
    async reset({ email }) {
      this.reset_errors();
      const { $api } = useNuxtApp();
      const { error } = await $api.auth.reset({ email });
      if (error.value) {
        this.validation_errors(error.value.data);
        return false;
      }
      return true;
    },
    async confirm_registration({ key }) {
      const { $api } = useNuxtApp();
      const { data, error } = await $api.auth
        .confirm_registration({ key: key });

      if (error.value) {
        this.validation_errors(error.value.data);
        this.status = { registrationCompleted: false };
        return false;
      }
      this.status = {
        registrationCompleted: true,
        registrationMethod: data.value.method
      };
      return true;
    },
    async accept_registration_with_password(formdata) {
      const { $api } = useNuxtApp();
      const { data, error, status } = await $api.auth
        .accept_registration_with_password(formdata);
      if (status.value === "success") {
        this.status = {
          registrationCompleted: true,
          registrationMethod: data.value.method
        };
        return true;
      } else {
        this.validation_errors(error.value.data);
        this.status = { registrationCompleted: false };
        return false;
      }
    },
    async register_failure(data) {
      this.message = data.msg;
      this.errors = data.errors;
      if (data.errors && data.errors.non_field_errors) {
        this.errors.non_field_errors = data.errors.non_field_errors;
        if (!isArray(this.errors.non_field_errors)) {
          this.errors.non_field_errors = [this.errors.non_field_errors]
        }
        delete data.errors.non_field_errors;
      } else {
        this.errors.non_field_errors = [];
      }
      this.code = data.code;
      if (this.code === "user_already_registered") {
        // stupid hack until the backend sends the message as a
        // message
        this.message = data.errors.key;
      }
      if (this.code === "link_expired") {
        // stupid hack until the backend sends the message as a
        // non_field_error
        this.errors.non_field_errors = data.errors.key;
      }

      this.status = { registrationCompleted: false };
      this.code = data.code;
      // When an email confirmation key is expired, we return the key from the
      // backend so we can use it to resend the confirmation email
      this.key = data.key;
      if (data.key == null) delete this.key;
    },
    async confirm_reset_password(reset_data) {
      this.reset_errors();
      const { $api } = useNuxtApp();
      const {
        data,
        error
      } = await $api.auth.confirm_reset_password(reset_data);
      if (error.value) {
        this.validation_errors(error.value.data);
        return false;
      }
      this.status = {
        resetCompleted: true, resetMethod: data.value.method
      };
      return true;
    },

    // "auth/initialise"
    // "auth/refresh"
    // "auth/clearErrors"
    // _register: "auth/register"
    // "auth/accept_privacy_policy"
    // _reset_errors: "auth/reset_errors"
    // "auth/getUser"
    // "auth/reset"
    // "auth/confirm_registration"
    // "auth/accept_registration"
    // "auth/registerFailure"
    // "auth/confirm_reset_password"

    async login({ email, password }) {
      // Update store to indicate that login attempt is happening
      const { $api } = useNuxtApp();

      // commit login request into state
      this.status = { loggingIn: true };
      this.code = "";
      try {
        const {data, error, status} = await $api.auth
          .authenticate({ email, password });

        const response = data.value;
        if (status.value === "success") {
          if (response.url) {
            // Store onelogin token in localstorage or in relayState
            localStorage.setItem(
              "saml",
              JSON.stringify({
                token: response.token,
                uuid: response.uuid
              })
            );
            window.location.assign(response.url);
          } else if (
            response.code &&
            response.code === "password_based_signin"
          ) {
            this.code = response.code;
          } else if (
            response.code &&
            response.code === "saml_based_signin"
          ) {
            this.code = response.code;
          }
        } else if (String(error.value.name) === "FetchError") {
          this.errors = {
            non_field_errors: "You are offline"
          }
        }

        return response;
      } catch (err) {
      }
    },
    async password_based_signin({ email, password }) {
      const { $api } = useNuxtApp();

      // commit login request into state
      this.status = { loggingIn: true };
      this.code = null;

      return await $api.auth
        .login({ email, password })
        .then(async (response) => {
          const userAuth: TokenRef<Record<string, string>> = useJwtTokens();

          userAuth.value = {
            access: response.access,
            refresh: response.refresh
          };
          return await this.after_login();
        })
        .catch((error) => {
          this.validation_errors(error.data);
        });
    },
    async saml_login({key}) {
      const { $api } = useNuxtApp();
      const {data, status } = await $api.auth.saml({key});

      if (status.value === "success") {
        const userAuth: TokenRef<Record<string, string>> = useJwtTokens();

        userAuth.value = {
          access: data.value.access,
          refresh: data.value.refresh
        };
        const user = useUserStore();
        await user.refresh();
        const router = useRouter()
        router.push({path:"/"});
      }
    },
    async after_login() {
      // Detect user status
      if (this.code === USER_STATUSES.PRIVACY) {
        return;
      }
      // Navigate to intended path if it exists.
      // This is only valid after login.
      const path = localStorage.getItem("intended_path");
      const router = useRouter();
      if (path !== null) {
        localStorage.removeItem("intended_path");
        router.push(JSON.parse(path).fullPath);
      } else {
        router.push("/");
      }
    },
    async restartLogin() {
    },
    async restart_login() {
    },
    async logout() {
      const userAuth: TokenRef<Record<string, string>> = useJwtTokens();
      userAuth.remove();
      const user = useUserStore();
      user.$reset();
    },
    async su_login({id}) {
      // Check if the user is a superuser
      const user = useUserStore();
      const tokens = useJwtTokens();
      if (!user.is_superuser()) {
        return false;
      }

      // Copy su_tokens to jwt_tokens
      tokens.su_login();
      const { $api } = useNuxtApp();
      const { data, status } = await $api.auth
        .su_login({ id: id });

      if (status.value === "success") {
        tokens.value = {
          access: data.value.access,
          refresh: data.value.refresh
        };
        await user.refresh();
        const router = useRouter()
        router.go(0);
      } else {
        // eslint-disable-next-line no-console
        console.log("SU_LOGIN FAILED")
      }
    },
    async su_logout() {
      const tokens = useJwtTokens();
      tokens.su_logout();
      const user = useUserStore();
      user.refresh();
      const router = useRouter()
      router.push({path:"/"});
    },
    async resend_account_creation_confirmation_email(registration_data) {
      const { $api } = useNuxtApp();
      const { data, error, status } = await $api.auth
        .resend_account_creation_confirmation_email(registration_data);

      this.reset_errors();
      if (status.value === "success") {
        this.parse_response(data.value);
        this.restart_login();
        return true;
      }
      this.validation_errors(error.value.data);
      return false;
    },
    async parse_errors() {
    },
    async parse_response(data) {
      this.message = data.msg;
      this.code = data.code;
    },

    // _login: "auth/login",
    // "auth/" + AUTH_ACTIONS.PASSWORD_BASED_SIGNIN,
    // "auth/restartLogin"
    // _logout: "auth/logout",
    // _su_login: "auth/su_login"
    // _su_logout: "auth/su_logout",
    // _resendConfirmationEmail: "auth/resendConfirmationMail2",
    // resendConfirmationEmail: "auth/resendAccountCreationConfirmationEmail",
    // "auth/resendConfirmationMail"
    // "auth/" + AUTH_ACTIONS.PARSE_ERRORS

    async clearErrors() {
    },
    async reset_errors() {
      this.errors = {};
      this.message = "";
    },
    async validation_errors(data) {
      if (data.errors) {
        this.errors = data.errors;
      } else {
        this.errors = { non_field_errors: ["Could not perform login."] };
      }
      this.code = data.code;
      this.message = data.msg;
      this.status = {};
      // When an email confirmation key is expired, we return the key from the
      // backend, so we can use it to resend the confirmation email
      this.key = data.key;
      if (data.key == null) this.key = null;
      if ([
        "link_expired",
        "invalid",
        "user_already_registered"].includes(this.code)) {
        // stupid hack until the backend sends the message as a
        // non_field_error
        this.non_field_errors.push(data.errors.key[0]);
        if (this.errors.non_field_errors == null) {
          this.errors.non_field_errors = [];
        }
        this.errors.non_field_errors.push(data.errors.key[0]);
      }
    },
  }
});
