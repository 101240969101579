
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexjXfg20vSWiqV3Wel4ayAAWoFt0maAgUV4XGStNqb8sIMeta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as listchKhmm6BDo4Z9Zle2PU_45DnFFcbCtOhRZ2ijM6uOZ8AEMeta } from "/vercel/path0/src/pages/job/list.vue?macro=true";
import { default as saml2canLFq_45bz1lQtzao1sqDwunGKwAS8oxyFHmg0v4zVUMeta } from "/vercel/path0/src/pages/auth/saml.vue?macro=true";
import { default as listBQ0AAbSlmi2MQcLmFFtkIPyEObk1wBywfLGBdqvlSgIMeta } from "/vercel/path0/src/pages/user/list.vue?macro=true";
import { default as loginzh_45RhYkMBImZRajQI1CvW8F_4591Zf80LrHic8_45Jz2QLwMeta } from "/vercel/path0/src/pages/auth/login.vue?macro=true";
import { default as resetTRZ6JB2_d7N80pEu2k6Q427255HapBf516u_gNSK5UsMeta } from "/vercel/path0/src/pages/auth/reset.vue?macro=true";
import { default as listT8_ca0uOLOEbNz3m_MQMs3eKJfNzVdARD1UZW7zzunwMeta } from "/vercel/path0/src/pages/issue/list.vue?macro=true";
import { default as indexP9MogACCyE0c_yVr2fzPjYcEu38hLRkySVvoBdJ13JQMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as list4ik_cGqDULOuPo_T_45d_45XTcVOwP2Fp_45UinCQA1v_FpS4Meta } from "/vercel/path0/src/pages/locker/list.vue?macro=true";
import { default as detailZ06MUv7tyDBDsV4kaNIV3my1kpNnWd0kqa2_45BUJ5z5kMeta } from "/vercel/path0/src/pages/user/detail.vue?macro=true";
import { default as detailYRmpRY7j7E9r__Mwjfu6ANma7Dqkk8usg4U_45reuqZfkMeta } from "/vercel/path0/src/pages/issue/detail.vue?macro=true";
import { default as listwrWqrgEgHUO38A9uWjARUgUPp_JlUnDYR_EAVYVTRNUMeta } from "/vercel/path0/src/pages/machine/list.vue?macro=true";
import { default as list6o2xfhiEIXSzQwERVeNc0eMBzNQkiQXluTLFapxtDzkMeta } from "/vercel/path0/src/pages/project/list.vue?macro=true";
import { default as privacy4GJQ5WvnL5RfSHjQD1ZLAfMFtAbAFgZwgs3FJZP0RrkMeta } from "/vercel/path0/src/pages/user/privacy.vue?macro=true";
import { default as detail7gxb_z_O61fSgoCEaIcnyRLfNxoEOkOU5PvzmPv1pjUMeta } from "/vercel/path0/src/pages/locker/detail.vue?macro=true";
import { default as loggedoutZQVnh1gRFwBH81AjCaq8Fcnj0Ml_lmFra14oQ6d3LqQMeta } from "/vercel/path0/src/pages/auth/loggedout.vue?macro=true";
import { default as detaileBfHp9GAHtwKcaTNA_45LxiVxlg8Ooq5_hVLR6m5g2y98Meta } from "/vercel/path0/src/pages/machine/detail.vue?macro=true";
import { default as detailR81Wu2cOhqZMLxCRD3uG3GOc544t9VGCqftWpDiQ0HgMeta } from "/vercel/path0/src/pages/problem/detail.vue?macro=true";
import { default as list9Ogk_45_4w_45LzUellIb2hGffzWYjdCPMeH5H1Ox4ZWlwwMeta } from "/vercel/path0/src/pages/usergroup/list.vue?macro=true";
import { default as listCt7tIti5kpBfgCN9oftlAvdn1wpAgsIB_juNcRNHJbkMeta } from "/vercel/path0/src/pages/workfloor/list.vue?macro=true";
import { default as list_457yx5zeEkxphVcjOwYT_45_3hqFubVBtFhlejFZtoGfdgMeta } from "/vercel/path0/src/pages/workspace/list.vue?macro=true";
import { default as listPCFE3stkwuxzzRwn5VuqlPl2VcTD1ylGCipB9n5FMhcMeta } from "/vercel/path0/src/pages/toolfamily/list.vue?macro=true";
import { default as bookingsMNw0IrGHy_459SQS2JmMtRU_45HDXSE5sz6DfyHt9DcCrAwMeta } from "/vercel/path0/src/pages/machine/bookings.vue?macro=true";
import { default as list_45wjdglO_aYWOl2x7Ymy8Fm_45QkMF7FQThqTp3BIG4qtIMeta } from "/vercel/path0/src/pages/staffmember/list.vue?macro=true";
import { default as detailwIbI89OCEYYjUK61sCB7RCg9CMBEsg_45FIpTcu0Fu_LoMeta } from "/vercel/path0/src/pages/usergroup/detail.vue?macro=true";
import { default as detailblT_c0SbCFb_459sgdwGCM7XVfDlkecRUmKr28bpR7vy8Meta } from "/vercel/path0/src/pages/workspace/detail.vue?macro=true";
import { default as sitetSzWO9s6R_xNzT5iuDtEoQis_iXTuDFmM_AfvUn5reEMeta } from "/vercel/path0/src/pages/auth/confirmation/site.vue?macro=true";
import { default as resetz3R3cjNnGnKYUJIC_cTx3lWqlT9sym_kMgGQanLixbcMeta } from "/vercel/path0/src/pages/auth/confirmation/reset.vue?macro=true";
import { default as inviteUyqMUqIgC4ibw390P_45saw6j_k555fFr8oKGQwpmViNIMeta } from "/vercel/path0/src/pages/auth/confirmation/invite.vue?macro=true";
import { default as confirmationFPEjX09GmiXjsxOwjTh_45vHqTAZw_mM9MMxXPafxLBIwMeta } from "/vercel/path0/src/pages/auth/confirmation.vue?macro=true";
import { default as detailbXsrahsaYZA7g2XA6n_45NT9XRk14x3Otd_GYIWvb3M_45oMeta } from "/vercel/path0/src/pages/toolfamily/detail.vue?macro=true";
import { default as editlpMt_h6l_qoaup3gct_Ny_90uxgqgT61N9e2wKsq19wMeta } from "/vercel/path0/src/pages/user/profile/edit.vue?macro=true";
import { default as listQBv04E7odv7ZfQ7KiIzXxkEZn7BKyPWBSHA3CXahMJ4Meta } from "/vercel/path0/src/pages/machinefamily/list.vue?macro=true";
import { default as dashboardyYtaGfTIYOmqt0ABQfu2xSjDUq_45VJWG8P7cj0TaFmQ0Meta } from "/vercel/path0/src/pages/reporting/dashboard.vue?macro=true";
import { default as detailWdb5uMkFH7_MqV0OAi9HpcTaDq2L_453of_451TCXI3FAiYMeta } from "/vercel/path0/src/pages/machinefamily/detail.vue?macro=true";
import { default as successFNzx_q8eq7auw1m_e0paqYJpwm_R1LPpq5O0fBVFyjkMeta } from "/vercel/path0/src/pages/auth/registration/success.vue?macro=true";
import { default as indexNn_IZVh62Kg7ti1vO8cokk6S0qLV_W060pD94y_45ibtYMeta } from "/vercel/path0/src/pages/user/settings/badge/index.vue?macro=true";
import { default as confirmq3n8r9QZbiqJYfGcBOhB4aRRRrZyfiTfeSfGJfloYS4Meta } from "/vercel/path0/src/pages/user/profile/email/confirm.vue?macro=true";
import { default as successm14YJ7A7CX68owv8bhfkAEKZ5cl55mQCeEb_QDfBoJYMeta } from "/vercel/path0/src/pages/user/settings/badge/success.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "job-list",
    path: "/job/list",
    component: () => import("/vercel/path0/src/pages/job/list.vue")
  },
  {
    name: "auth-saml",
    path: "/auth/saml",
    component: () => import("/vercel/path0/src/pages/auth/saml.vue")
  },
  {
    name: "user-list",
    path: "/user/list",
    component: () => import("/vercel/path0/src/pages/user/list.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/vercel/path0/src/pages/auth/login.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    component: () => import("/vercel/path0/src/pages/auth/reset.vue")
  },
  {
    name: "issue-list",
    path: "/issue/list",
    component: () => import("/vercel/path0/src/pages/issue/list.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/src/pages/admin/index.vue")
  },
  {
    name: "locker-list",
    path: "/locker/list",
    component: () => import("/vercel/path0/src/pages/locker/list.vue")
  },
  {
    name: "user-detail",
    path: "/user/detail",
    component: () => import("/vercel/path0/src/pages/user/detail.vue")
  },
  {
    name: "issue-detail",
    path: "/issue/detail",
    component: () => import("/vercel/path0/src/pages/issue/detail.vue")
  },
  {
    name: "machine-list",
    path: "/machine/list",
    component: () => import("/vercel/path0/src/pages/machine/list.vue")
  },
  {
    name: "project-list",
    path: "/project/list",
    component: () => import("/vercel/path0/src/pages/project/list.vue")
  },
  {
    name: "user-privacy",
    path: "/user/privacy",
    component: () => import("/vercel/path0/src/pages/user/privacy.vue")
  },
  {
    name: "locker-detail",
    path: "/locker/detail",
    component: () => import("/vercel/path0/src/pages/locker/detail.vue")
  },
  {
    name: "auth-loggedout",
    path: "/auth/loggedout",
    component: () => import("/vercel/path0/src/pages/auth/loggedout.vue")
  },
  {
    name: "machine-detail",
    path: "/machine/detail",
    component: () => import("/vercel/path0/src/pages/machine/detail.vue")
  },
  {
    name: "problem-detail",
    path: "/problem/detail",
    component: () => import("/vercel/path0/src/pages/problem/detail.vue")
  },
  {
    name: "usergroup-list",
    path: "/usergroup/list",
    component: () => import("/vercel/path0/src/pages/usergroup/list.vue")
  },
  {
    name: "workfloor-list",
    path: "/workfloor/list",
    component: () => import("/vercel/path0/src/pages/workfloor/list.vue")
  },
  {
    name: "workspace-list",
    path: "/workspace/list",
    component: () => import("/vercel/path0/src/pages/workspace/list.vue")
  },
  {
    name: "toolfamily-list",
    path: "/toolfamily/list",
    component: () => import("/vercel/path0/src/pages/toolfamily/list.vue")
  },
  {
    name: "machine-bookings",
    path: "/machine/bookings",
    component: () => import("/vercel/path0/src/pages/machine/bookings.vue")
  },
  {
    name: "staffmember-list",
    path: "/staffmember/list",
    component: () => import("/vercel/path0/src/pages/staffmember/list.vue")
  },
  {
    name: "usergroup-detail",
    path: "/usergroup/detail",
    component: () => import("/vercel/path0/src/pages/usergroup/detail.vue")
  },
  {
    name: "workspace-detail",
    path: "/workspace/detail",
    component: () => import("/vercel/path0/src/pages/workspace/detail.vue")
  },
  {
    name: "auth-confirmation",
    path: "/auth/confirmation",
    component: () => import("/vercel/path0/src/pages/auth/confirmation.vue"),
    children: [
  {
    name: "auth-confirmation-site",
    path: "site",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/site.vue")
  },
  {
    name: "auth-confirmation-reset",
    path: "reset",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/reset.vue")
  },
  {
    name: "auth-confirmation-invite",
    path: "invite",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/invite.vue")
  }
]
  },
  {
    name: "toolfamily-detail",
    path: "/toolfamily/detail",
    component: () => import("/vercel/path0/src/pages/toolfamily/detail.vue")
  },
  {
    name: "user-profile-edit",
    path: "/user/profile/edit",
    component: () => import("/vercel/path0/src/pages/user/profile/edit.vue")
  },
  {
    name: "machinefamily-list",
    path: "/machinefamily/list",
    component: () => import("/vercel/path0/src/pages/machinefamily/list.vue")
  },
  {
    name: "reporting-dashboard",
    path: "/reporting/dashboard",
    component: () => import("/vercel/path0/src/pages/reporting/dashboard.vue")
  },
  {
    name: "machinefamily-detail",
    path: "/machinefamily/detail",
    component: () => import("/vercel/path0/src/pages/machinefamily/detail.vue")
  },
  {
    name: "auth-registration-success",
    path: "/auth/registration/success",
    component: () => import("/vercel/path0/src/pages/auth/registration/success.vue")
  },
  {
    name: "user-settings-badge",
    path: "/user/settings/badge",
    component: () => import("/vercel/path0/src/pages/user/settings/badge/index.vue")
  },
  {
    name: "user-profile-email-confirm",
    path: "/user/profile/email/confirm",
    component: () => import("/vercel/path0/src/pages/user/profile/email/confirm.vue")
  },
  {
    name: "user-settings-badge-success",
    path: "/user/settings/badge/success",
    component: () => import("/vercel/path0/src/pages/user/settings/badge/success.vue")
  }
]