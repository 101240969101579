const EquipmentList = () => import("~/pages/machine/list.vue");
const MachineDetail = () => import("~/pages/machine/detail.vue");
const MachineFamilyDetail = () => import("~/pages/machinefamily/detail.vue");
const MachineFamilyList = () => import("~/pages/machinefamily/list.vue");
const MachineBookings = () => import("~/pages/machine/bookings.vue");

export default  {
  routes: [
    {
      path: "/machine/list",
      name: "machine_list",
      component: EquipmentList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"]
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/detail/:id",
      component: MachineDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/detail/:id/edit",
      component: MachineDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.write"],
        allowed_object_permissions: ["machine.write"],
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/settings/:id",
      component: MachineDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"],
        crudMode: "read",
        contentMode: "settings",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/settings/:id/edit",
      component: MachineDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.write"],
        allowed_object_permissions: ["machine.write"],
        crudMode: "update",
        contentMode: "settings",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/access/:id",
      component: MachineDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"],
        crudMode: "read",
        contentMode: "access",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machine/bookings/",
      component: MachineBookings,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"],
        crudMode: "read",
        contentMode: "bookings",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machinefamily/list",
      component: MachineFamilyList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machinefamily.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machinefamily/detail/:id",
      component: MachineFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machinefamily.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machinefamily/detail/:id/edit",
      component: MachineFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machinefamily.write"],
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/machinefamily/access/:id",
      component: MachineFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machinefamily.read"],
        crudMode: "read",
        contentMode: "access",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
  ]
}
