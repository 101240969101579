import { useUserStore } from "~/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Do not run on the server
  if (import.meta.server) return;
  if (to.meta.layout === "authenticated-layout") {
    const userStore = useUserStore();
    const logged_in = await userStore.retrieve();
    if (!logged_in) {
      localStorage.setItem("intended_path", JSON.stringify(to));
      // BUGBUG: we need to hardcode a path here instead of a named route
      return reloadNuxtApp({
        path: "/login",
        force: true,
        persistState: false
      });
    }
    const active = await userStore.active();
    if (!active) {
      // Do a soft reload of the login flow, without resetting state
      const router = useRouter()
      router.push("/login")
    }
    if (to.path === "/") {
      const config = useConfigStore();
      if (config.has_feature("reports")) {
        return { name: "dashboard" };
      } else {
        return { name: "machine_list" };
      }
    }
  }
});
