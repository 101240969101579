const url = "/api/reservations/reservation/";

export default (fetch) => ({
  /*
   * Get all machines from API
   */
  add(event) {
    return fetch(url, {
      method: "POST",
      body: {
        ...event,
      },
    });
  },
  all() {
    return fetch(`${url}?no_page=true`);
  },
  load(timeslot) {
    const _url =
      `${url}?no_page=true` +
      `&booked_after=${timeslot.start}` +
      `&booked_before=${timeslot.end}`;
    return fetch(_url);
  },
  update(event_id, event) {
    return fetch(url + event_id + "/", {
      method: "PATCH",
      body: event,
    });
  },
  delete(event_id) {
    return fetch(url + event_id + "/", {
      method: "DELETE",
    });
  },
});
