import { useCustomFetch } from "~/hooks/useCustomFetch.ts";

const machines_url = "/api/machine_core/machine/";
const url_prefix = "/api/mcu_core/mcu/";

export default () => ({
  /*
   * Get paginated machines from API
   */
  load() {
    return useCustomFetch(machines_url);
  },

  /*
   * Get all machines from API
   */
  all() {
    return useCustomFetch(machines_url + "?no_page=true");
  },
  my() {
    return useCustomFetch(machines_url + "?filter=my");
  },

  /*
   * Get machine and MCU Settings from API
   */
  loadSettings(id, form = false) {
    let url = `${url_prefix}${id}/`;
    if (form) url += "?form";
    return useCustomFetch(url);
  },

  /*
   * update machine settings with ID
   *
   */
  updateSettings(id, { mcu_type, settings }) {
    let url = `${url_prefix}${id}/`;

    return useCustomFetch(url, {
      method: "PATCH",
      body: { mcu_type, settings },
    });
  },
});
