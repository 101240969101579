import { useCustomFetch } from "~/hooks/useCustomFetch";

const current_user_url = "/api/user_core/current/";
const accept_privacy_policy_url =
  "/api/auth/registration/privacy-policy/accept/";
const cancel_change_email_address_url =
  "/api/user_core/current/cancel_change_email_address/";
const resend_confirm_changing_email_url =
  "api/user_core/current/resend_confirmation_mail/";
const confirm_change_email_url =
  "/api/user_core/current/confirm_change_email_address/";
const badge_registration_url = "/api/badge_core/assign_badge/";
const personal_data_download_url = "/api/user_core/personal_data_download/";
const personal_data_summary_url = "/api/user_core/personal_data_summary/";
const acknowledge_version_url = "/api/user_core/current/acknowledge_version/";

export default (fetch) => ({
  updateUser(userData) {
    return useCustomFetch(current_user_url, {
      method: "PATCH",
      body: userData,
    });
  },
  getCurrentUser() {
    return fetch(current_user_url);
  },
  deleteUser() {
    return useCustomFetch(current_user_url + "delete/", {
      method: "DELETE",
    });
  },
  cancel_change_email_address() {
    return useCustomFetch(cancel_change_email_address_url);
  },
  resend_change_emailaddress_confirmation_mail() {
    return useCustomFetch(resend_confirm_changing_email_url);
  },
  confirm_change_email({ key }) {
    return useCustomFetch(confirm_change_email_url, {
      method: "POST",
      body: { key },
    });
  },
  register_badge(token_code) {
    return useCustomFetch(badge_registration_url, {
      method: "POST",
      body: { code: token_code },
    });
  },
  accept_privacy_policy({ privacy_policy_accepted }) {
    return useCustomFetch(accept_privacy_policy_url, {
      method: "POST",
      body: { privacy_policy_accepted },
    });
  },
  personal_data_download() {
    return useCustomFetch(personal_data_download_url);
  },
  get_personal_data_summary() {
    return useCustomFetch(personal_data_summary_url);
  },
  acknowledgeVersion(version) {
    return useCustomFetch(acknowledge_version_url, {
      method: "PATCH",
      body: { acknowledged_version: version },
    });
  },
});
