import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/debug-hooks.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hfkeyICBpmHFsBXhXmy2zKGRpTQOJIxftd2IEuNha5s from "/vercel/path0/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import api_V7vXtjocYIgrYhTpn78AOlqodlI3uYkEnKOo8M83mEs from "/vercel/path0/src/plugins/api.ts";
import click_outside_Tfk0BXLIj2hVjKnovzK6baPZRc9hpWCzFxX5Juca1O4 from "/vercel/path0/src/plugins/click-outside.ts";
import sanitize_pZuj66O_ihIjg4cYNL8bgm15Ng3dwHyVGTI5l9eK_aw from "/vercel/path0/src/plugins/sanitize.ts";
import sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4 from "/vercel/path0/src/plugins/sentry.client.ts";
import tooltip_NJjD3e9Jh0pncaKITpk9cZBlRE0R7ujN3aN6YW_gOGo from "/vercel/path0/src/plugins/tooltip.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_hfkeyICBpmHFsBXhXmy2zKGRpTQOJIxftd2IEuNha5s,
  api_V7vXtjocYIgrYhTpn78AOlqodlI3uYkEnKOo8M83mEs,
  click_outside_Tfk0BXLIj2hVjKnovzK6baPZRc9hpWCzFxX5Juca1O4,
  sanitize_pZuj66O_ihIjg4cYNL8bgm15Ng3dwHyVGTI5l9eK_aw,
  sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4,
  tooltip_NJjD3e9Jh0pncaKITpk9cZBlRE0R7ujN3aN6YW_gOGo
]